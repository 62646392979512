import { SxProps } from '@mui/material'
import StylesOrderServiceInterface from '~/src/components/orders/order-layout/styles/StylesOrderServiceInterface'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'
import { PlatformUtils } from '@eo-storefronts/eo-core'

export default class StylesOrderServiceStyleOne extends TemplateServiceStyleBase implements StylesOrderServiceInterface {
  public getPageSx(): SxProps {
    return {
      px: 4,
      py: 2,
      minHeight: '100%',
      [this.muiTheme.breakpoints.down('md')]: {
        px: 2,
        pb: 2,
        pt: PlatformUtils().isCapacitorNative
          ? `calc(var(--eo-safe-area-top) + ${this.muiTheme.spacing(3)})`
          : 2
      },
      [this.muiTheme.breakpoints.down('sm')]: {
        p: 0,
        pb: 2,
        '.tabs-layout__content': {
          px: 2
        }
      }
    }
  }
}
