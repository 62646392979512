import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'
import StylesOrderServiceInterface from '~/src/components/orders/order-layout/styles/StylesOrderServiceInterface'
import StylesOrderServiceStyleTwo from '~/src/components/orders/order-layout/styles/StylesOrderServiceStyleTwo'
import StylesOrderServiceStyleOne from '~/src/components/orders/order-layout/styles/StylesOrderServiceStyleOne'

const getOrderTemplateService = (style: TemplateEnum | null, muiTheme: CustomMuiTheme): StylesOrderServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new StylesOrderServiceStyleTwo(muiTheme)
    default:
      return new StylesOrderServiceStyleOne(muiTheme)
  }
}

export default getOrderTemplateService
