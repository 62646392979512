import { useLayout } from '~/src/hooks/layout/useLayout'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import getOrderTemplateService from '~/src/components/orders/order-layout/styles/GetOrderTemplateService'
import StylesOrderServiceInterface from '~/src/components/orders/order-layout/styles/StylesOrderServiceInterface'
import useGetPageStyle from '~/src/hooks/layout/useGetPageStyle'

const useGetOrderTemplate = (): StylesOrderServiceInterface => {
  const muiTheme = useCustomMuiTheme()
  const pageStyle = useGetPageStyle('order')

  useLayout('order')

  return getOrderTemplateService(pageStyle, muiTheme)
}

export default useGetOrderTemplate
